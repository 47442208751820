<template>
  <div class="peo_img">
    <div v-for="(item,index) in visitVolume" :key="index" class="img"></div>
  </div>
</template>
<script>
export default {
  props: ['visitVolume'],
  data() {
    return {
      imgArr: []
    }
  }
}
</script>
<style lang="scss" scoped>
.peo_img {
  display: flex;
  flex-direction: row;
  align-items: center;
  .img {
    width: 22px;
    height: 26px;
    position: relative;
    margin-right: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAAHEcZzWAAAAAXNSR0IArs4c6QAAAqJJREFUSA2tVj1oFEEU/uYuhhjBnyBBLEREQZAU6UQIgpZqYaFircTWJhFuE1zw7iyMqI1YiZVF0EJQBCGgViKIEsXGyj8EfxAOg/iX5/d2dnZndvdI5Jxi573vffPN2zdv5w7QEclZnYw+0BDRqaaPdJw3pCxAMOgQpT1Xp4/GIudkra7Zp6gO45Sso89IZilySM1ktI2x27TMYYehH+vUNohlI37ifRaw6Nvcb0iHpE0K9CVomjpXvaafaieR9BHLsN3Q4F6Gx+ajBQV7M5BG8AIuYJnOS2dbOAe6LJyvMwuIlmlb0wWqiC7WjxHE5kW+XR1HXaw0k1jCMmBK9mNaRjI/NWzOkczxRPYUg4HPU9JSPCQ4FgS6OGE17CneZQW2kH+FVZjw1+XkSE4ylQt+MLG5vcPyalQRlRXJ7ZAci25bPSRvVqf8uZoZopYcm04IB94x5zllYA1WO9CbH6Btrnp+wZyWHXypAwU0cbOy8HDuE9kdkAy/nFUYRcN8UtxgVup4ht8BqexMMp1z+rl/52EMlOMFZD0Ga8si6rovuJlXoyBU4Y7lL1iMRjLOXS8SXlkMpf4PXmCn0DSXquJlYf1A/uApyfWqBRWYsKQ72c2P/VgoHEs/fuErM/XuTZ/exTa8wNZiCBNmwTGKddv6z6KqJLxcO9juRHUOhWPzkq/1yCcs057HGfPE54bCGmliF8Xv+KQl7DnmO1rkhDX2o9rh8xjHIiYJb/ZD3Fh/OGawApd5r1Z+Bd2FYxlid2yj8DCFih3S4a/PO5zGKxiT/HQGG9PJhWMZYEdc40EcKZKW9A2+8bROsM7XHdcKT0mTmUUO7GHWnj7Inr6lF0CDWbZ6ECsvreG4Cn+g8IZytAeEh6vtdoPp63+R/zXeUKj5FzutqpW56B4aAAAAAElFTkSuQmCC);
    background-size:22px 26px;
  }
}
</style>

 