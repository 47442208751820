<template>
  <div class="exchange_detail flex_column" v-if="detail">
    <div
      class="header flex_row border_bottom16"
      :class="[theme=='light'?'header_light':'header_dark']"
    >
      <div class="header_left flex_row">
        <img class="logo" :src="detail.logoUrl" alt />
        <div class="desc flex_column">
          <p class="p_1">{{basicGlobalizationInfo.exchangeName}}</p>
          <p class="p_2" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('24H成交额')}}</p>
          <div class="p_3 flex_row_s">
            <p>{{detail.volume24 | formateMoney}}</p>
            <p :class="[detail.volume24Change>0?'colorUp':'colorDown']">({{detail.volume24Change}}%)</p>
          </div>
        </div>
      </div>
      <!-- <div class="rank flex_column">
        <p class="p_1">全球综合排名</p>
        <div class="p_2 flex_row">
          <img src="@/assets/icon/del_icon_rank.png" alt />
          <p>NO.{{detail.serialNo}}</p>
        </div>
        <P class="p_3">{{detail.visitVolume}}关注</P>
      </div> -->
    </div>
    <div
      class="desc"
      :class="[theme=='light'?'desc_light':'desc_dark']"
    >{{basicGlobalizationInfo.exchangeDesc}}</div>
    <div class="infor_wrapper flex_column" :class="[theme=='light'?'bg_light':'bg_dark']">
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('成立时间')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.establishTime}}</p>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('资产实力')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.assetValue | formateMoney}}</p>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('人气指数')}}</p>
        <PeoImg :visitVolume="detail.visitVolume" />
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('注册地区')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{basicGlobalizationInfo.registrationArea}}</p>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('币种数量')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.currencyNum}}</p>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('KYC认证')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{$t(detail.kycAuth?'是':'否')}}</p>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('官网地址')}}</p>
        <a
          :href="detail.officialWebsiteUrl"
          class="link"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.officialWebsiteUrl}}</a>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('官网备用地址')}}</p>
        <a
          :href="detail.alternativeUrl"
          class="link"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.alternativeUrl}}</a>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('手续费用')}}</p>
        <a
          :href="detail.feesUrl"
          class="link"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{detail.feesUrl}}</a>
      </div>
      <div
        class="infor_item flex_row"
        :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"
      >
        <p class="label" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('交易支持')}}</p>
        <p
          class="p_1"
          :class="[theme=='light'?'color_2_light':'color_2_dark']"
        >{{basicGlobalizationInfo.transactionType}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { exchangeDetail, conversion } from "@/api/index";
import PeoImg from "@/components/peo-img";
var quoteCurrencyCode = "";
var priceConversion = "";
export default {
  components: {
    PeoImg
  },
  data() {
    return {
      basicGlobalizationInfo: "",
      detail: "",
      language: "",
      priceConversion: "", //汇率
      quoteCurrencyCode: "", //选中的货币
      theme: ""
    };
  },
  created() {
    this.exchangeId = this.$route.query.id;
    this.language = localStorage.getItem("currentNation") || this.$i18n.locale;
    this.theme = 'light';
    this.getData();
  },
  filters: {
    formateMoney: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    }
  },
  methods: {
    getData() {
      exchangeDetail(this.exchangeId).then(res => {
        if (res.resultStatus) {
          const resultData = res.resultData;
          this.detail = resultData;
          this.basicGlobalizationInfo =
            res.resultData.basicGlobalizationInfo[this.language] || {};
            console.log(this.basicGlobalizationInfo,'this.basicGlobalizationInfo');
            // this.basicGlobalizationInfo.exchangeDesc = '11111'
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "./theme.scss";
.exchange_detail {
  width: 100%;
  position: relative;
}
.header {
  height: 184px;
  position: relative;
  justify-content: space-between;
  .header_left {
    margin-left: 40px;
    .logo {
      width: 120px;
      height: 120px;
      position: relative;
      margin-right: 24px;
    }
    .desc {
      height: 120px;
      position: relative;
      justify-content: space-between;
      .p_1 {
        font-size: 32px;
        color: #666666;
      }
      .p_2 {
        font-size: 22px;
        color: #a4afb5;
      }
      .p_3 {
        align-items: flex-end;
        p:nth-child(1) {
          font-size: 32px;
          color: #51b463;
          margin-right: 8px;
        }
        p:nth-child(2) {
          font-size: 24px;
          // color: #51B463;
        }
      }
    }
  }
  .rank {
    height: 120px;
    justify-content: space-between;
    margin-right: 32px;
    // align-items: center;
    .p_1 {
      font-size: 20px;
      color: #a4afb5;
    }
    .p_2 {
      height: 46px;
      border-radius: 23px;
      border: 1px solid #51b463;
      justify-content: center;
      img {
        width: 22px;
        height: 20px;
        margin-right: 5px;
      }
      p {
        font-size: 24px;
        color: #51b463;
      }
    }
    .p_3 {
      font-size: 22px;
      text-align: center;
      color: #a4afb5;
      position: relative;
    }
  }
}
.desc {
  padding: 32px;
  font-size: 24px;
  white-space: pre-line;
}
.infor_wrapper {
  width: 100%;
  padding: 0px 32px;
  box-sizing: border-box;
  .infor_item {
    height: 88px;
    width: 100%;
    justify-content: space-between;
    .label {
      font-size: 24px;
      position: relative;
    }
    .p_1 {
      font-size: 24px;
      position: relative;
    }
    .link {
      width: 400px;
      font-size: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-decoration: none;
      cursor: pointer;
      text-align: right;
      text-overflow: ellipsis;
    }
  }
}
</style>

